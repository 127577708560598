
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FeedbackForm from '@/components/FeedbackForm.vue';
import { mdiBalloon } from '@mdi/js';

export default defineComponent({
  components: {
    FeedbackForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref();
    store.dispatch('fetchOpenFeedbacks').then((res: any) => {
      user.value = res.data.message.mine;
    });

    const save = () => {
      store.dispatch('fetchOpenFeedbacks').then((res: any) => {
        user.value = res.data.message.mine;
        // if (!user.value || !user.value.skills || user.value.skills.length == 0) {
        //   router.push({ name: 'Index' });
        // }
      });
    };

    return {
      user,

      save,
      mdiBalloon,
    };
  },
});
